<template>
  <div class="container h-100 bg-first p-1">
    <b-card
      class="mt-1"
      no-body
    >
      <div class="p-2">
        <h3 class="m-0">
          รายการสลากฯขายไปแล้วของฉัน
        </h3>
        <small>คุณจำหน่ายสลากได้ทั้งหมด
          <span class="text-danger">{{ totalRows }} ใบ</span> ในการจ่ายเงิน
          เพื่อเป็นเจ้าของรายการสลาก ฯ ที่ท่านสั่งซื้อ</small>
      </div>

      <div class="pl-2">
        <h4>
          เฮงมากมาก ค้าสลาก ขายไปทั้งหมด
          <span class="text-danger">{{ totalRows }} รายการ</span>
        </h4>
      </div>

      <div style="overflow-x: auto">
        <b-table
          :fields="fields"
          :items="items"
          responsive="sm"
        >
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status >= 2"
              pill
              variant="success"
            >
              ขายแล้ว
            </b-badge>
          </template>
          <template #cell(lottonumber)="data">
            <h3 class="font-weight-bolder font-medium-2">
              {{ data.item.lottonumber }}
            </h3>
          </template>
        </b-table>
      </div>
      <b-pagination
        v-model="currentPage"
        class="mt-1"
        hide-goto-end-buttons
        :total-rows="totalRows"
        first-number
        last-number
        align="center"
        @input="getLottery()"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Order',
  components: {
    BCard,
    BTable,
    BBadge,
    BPagination,
  },
  data() {
    return {
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      cart: [],
      lottoset: [],
      fields: [
        { key: 'index', label: '#' },
        { key: 'lottonumber', label: 'เลขสลาก' },
        { key: 'status', label: 'สถานะ' },
        { key: 'set_no', label: 'เล่มที่' },
        { key: 'roundth', label: 'งวดวันที่' },
      ],
      items: [
        {
          lotto_num: 123456,
          order_status: {
            status: 'จำหน่ายแล้ว',
            variant: 'success',
          },
          lotto_set: 1,
          buy_date: '2020-01-01',
        },
      ],
      lotto: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
    }
  },
  mounted() {
    this.getLottery()
  },
  methods: {
    getLottery() {
      const Obj = {
        page: this.currentPage,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/getsold', Obj)
        .then(response => {
          this.items = response.data.lottery
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    minus() {
      if (this.number > 1) {
        // eslint-disable-next-line no-plusplus
        this.number--
      }
    },
  },

  head() {
    return {
      title: 'รายการสั่งซื้อสลากฯ ทั้งหมดของคุณ',
    }
  },
}
</script>

<style scoped>
.table {
  border-spacing: 0 15px;
  border-collapse: separate;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  scrollbar-width: none;
}
table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
th {
  text-align: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.table tbody tr {
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.table tbody tr td:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.table tbody tr td {
  background: #fff;
}
.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
</style>
